import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {useState} from "react";
import {useAppDispatch} from "../store/store";
import CustomLoader from "../components/custom-loader/custom-loader";

let TopBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ModalContainer = styled(Box)({
  backgroundColor: "white",
});

interface Props {
  onClose: any;
  errors: any;
}

const MAIN_TEST_ID = "job-creation-warning";

export const JobCreationWarningModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  console.log("props.errors", props.errors);

  const onClose = (data: any) => {
    props.onClose(data);
  };

  return (
    <Dialog open={true} onClose={() => {
    }}>
      <ModalContainer sx={{p: 3}}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Warning!</Typography>
          </div>
        </TopBox>
        <DialogContent sx={{px:0}}>
          <Box>

            <Box fontWeight={'bold'} sx={{mb: 3}}>Something went wrong with job creation.</Box>

            {!!props.errors && <ul>
              {Object.keys(props.errors).map((key: any) => {
                console.log("key", key);
                return <li><Box>{props.errors[key]}</Box></li>
              })}
            </ul>}

          </Box>
        </DialogContent>
        <Box display={"flex"} justifyContent={"space-around"} sx={{mt: 4}}>
          <Box
            sx={{width: "33%"}}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Button
              data-testid={`${MAIN_TEST_ID}_go-back_button`}
              onClick={onClose}
              variant={"text"}
              color={'error'}
            >
              Cancel
            </Button>
          </Box>
          <Box
            sx={{width: "33%"}}
            display={"flex"}
            justifyContent={"center"}
          >
            <Button
              type="button"
              onClick={() => onClose({success: 1})}
              data-testid={`${MAIN_TEST_ID}_continue_button`}
              variant={"contained"}
              color={"error"}
            >
              {isProcessing && (
                <Box sx={{mr: 3}}>
                  <CustomLoader size={20}/>
                </Box>
              )}
              Optimize Anyway
            </Button>
          </Box>
        </Box>
      </ModalContainer>
    </Dialog>
  );
};
export default JobCreationWarningModal;

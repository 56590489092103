import styled from "@emotion/styled";
import {Box, Button, Typography} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {summaryColumns} from "./summaryConfig";
import {RootState, useAppDispatch} from "../../store/store";
import {PageContainer} from "../../styles/PageContainer";
import {PrimaryButton} from "../../styles/PrimaryButton";
import {
  clearSummaryData,
  getSummaryList,
  updateSummaryPerAddressFilename,
  updateSummaryValidationStatus,
} from "../../store/summarySlice";
import {useSelector} from "react-redux";
import {patchJob, postSetAttrs} from "../../store/jobSlice";
import CustomLoader from "../../components/custom-loader/custom-loader";
import {GeneralToast} from "../../utils/toasts";
import {getCoMailFacilitySlimList} from "../../store/coMailFacilitySlice";
import {JobSummaryModel} from "../../models/JobSummaryModel";
import {PaginatorModel} from "../../models/PaginatorModel";
import CustomSummaryTable from "../../components/custom-summary-table/custom-summary-table";
import {setModalContent} from "../../store/utilsSlice";
import JobCreationWarning from "../../modals/job-creation-warning";

let TopBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ContentContainerInner = styled.div({
  flexGrow: 1,
  maxWidth: "1800px",
  width: "100%",
  position: "relative",
});

function SummaryPage() {
  const summary = useSelector((state: RootState) => state.summary.summary);
  const patchJobsStatus = useSelector(
    (state: RootState) => state.job.patchJobsStatus
  );
  const postSetAttrsStatus = useSelector(
    (state: RootState) => state.job.postSetAttrsStatus
  );
  const loadingSummaryListStatus = useSelector(
    (state: RootState) => state.summary.loadingSummaryListStatus
  );
  const getCoMailFacilitiesSlimStatus = useSelector(
    (state: RootState) => state.coMailFacility.getCoMailFacilitiesSlimStatus
  );
  const [data, setData] = useState<Array<any>>([]);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [proceedInProgress, setProceedInProgress] = useState<boolean>(false);
  const [pageQueryParams, setPageQueryParams] = useState<PaginatorModel | null>(
    null
  );
  const columns = useMemo(() => summaryColumns, []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pendingPromises = useRef(0);
  const routeParams = useParams();

  useEffect(() => {
    setData(summary);
  }, [summary]);

  useEffect(() => {
    dispatch(clearSummaryData());
    if (routeParams?.id) {
      dispatch(getCoMailFacilitySlimList()).then((result) => {
        if (getCoMailFacilitySlimList.fulfilled.match(result)) {
          dispatch(
            getSummaryList({
              payload: {pk: routeParams?.id},
            })
          ).then((result2) => {
            result2.payload.forEach((x: any, index: number) => {
              dispatch(
                updateSummaryPerAddressFilename({
                  fileName: x.file_name,
                  field: "co_mail_facilities",
                  value: result.payload,
                })
              );
            });
          });
        } else {
          GeneralToast.error("Problem with Co-Mail Facility list");
          navigate("/overview");
        }
      });
    } else {
      GeneralToast.error("Problem with reading BCT file name");
      navigate("/overview");
    }
    dispatch(updateSummaryValidationStatus(null));
    return () => {
    };
  }, []);

  const handleRowClick = (e: any, cell: any) => {
    e.preventDefault();
    e.stopPropagation();
    const customAttribute: string | null =
      e.target.getAttribute("data-custom-action");
    const readonlyAttribute: boolean | null =
      e.target.getAttribute("data-readonly") === "true";
    if (!customAttribute || readonlyAttribute) return;
  };

  const confirmJobs = async () => {
    setProceedInProgress(true);
    let result = await saveAttrs();
    if (result) {
      await handlePatchJob();
    } else {
      setProceedInProgress(false);
    }
  }
  const saveAttrs = async () => {
    return new Promise(async (resolve, reject) => {
      if (
        summary
          .flatMap((obj: any) => obj.versions)
          .filter(
            (x: JobSummaryModel) =>
              !x.hasOwnProperty("co_mail_facilities") ||
              !x?.co_mail_facilities?.length
          ).length
      ) {
        GeneralToast.error(
          "Each version must have at least one facility assigned!"
        );
        dispatch(updateSummaryValidationStatus(true));
        resolve(false);
      }
      if (pendingPromises.current > 0) return;
      let setAttrsPayload = summary
        .flatMap((obj: any) => obj.versions)
        .map(
          ({
             co_mail_facilities,
             scheduled,
             is_hd_only,
             solvent_ink,
             cr_bundle_protection_enabled,
             scf_pallet_protection_enabled,
             adc_ndc_pallet_protection_enabled,
             pk,
             add_a_name_amount,
           }: JobSummaryModel) => {
            return {
              scheduled,
              is_hd_only,
              solvent_ink,
              cr_bundle_protection_enabled,
              scf_pallet_protection_enabled,
              adc_ndc_pallet_protection_enabled,
              pk: pk,
              add_a_name_amount,
              co_mail_facilities_pks: co_mail_facilities?.length
                ? co_mail_facilities.map((x: any) => x.pk)
                : [],
            };
          }
        );

      let result: any = await dispatch(
        postSetAttrs({pk: routeParams?.id, payload: setAttrsPayload})
      );
      if (postSetAttrs.fulfilled.match(result)) {
        GeneralToast.success("Data has been saved");
        resolve(true);
      } else {
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
          "Problem during validation data"
        );
        resolve(false);
      }

    })
  };

  const handlePatchJob = async () => {
    return new Promise(async (resolve, reject) => {
      let payload: { pk?: string } = {
        pk: routeParams?.id,
      };
      let result: any = await dispatch(patchJob(payload));
      if (patchJob.fulfilled.match(result)) {
        navigate("/overview");
        GeneralToast.success("Job has been created");
        resolve(true);
        setProceedInProgress(false);
      } else {
        if (result?.payload?.response?.data?.error_code === 'DL-731088') {
          dispatch(setModalContent(<JobCreationWarning errors={result?.payload?.response?.data?.details}
                                                       onClose={onCloseJobCreationWarning}/>));
        } else {
          setProceedInProgress(false);
          GeneralToast.error(
            result?.payload?.response?.data?.message ??
            "Something went wrong. Please try again later"
          );
          resolve(false);
        }
      }

    })
  };

  const onCloseJobCreationWarning = async (data: any) => {
    dispatch(setModalContent(null));
    if (data && data.success) {
      let payload: { pk?: string; queryParams: { with_additional_validation: boolean } } = {
        pk: routeParams?.id,
        queryParams: {
          with_additional_validation: false
        }
      };
      let result: any = await dispatch(patchJob(payload));
      setProceedInProgress(false);
      if (patchJob.fulfilled.match(result)) {
        navigate("/overview");
        GeneralToast.success("Job has been created");
      } else {
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
          "Something went wrong. Please try again later"
        );
      }
    } else {
      setProceedInProgress(false);
    }
  };


  const onCancel = () => {
    navigate("/overview");
  };

  const onSave = async () => {
    setSaveInProgress(true);
    let result = await saveAttrs();
    setSaveInProgress(false);
  };

  return (
    <ContentContainerInner>
      <PageContainer sx={{my: 4, mx: 2, p: 3}}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Summary</Typography>
            <Typography variant="subtitle2">
              View all recent pools and take actions
            </Typography>
          </div>
        </TopBox>
        {!!(loadingSummaryListStatus === "loading" || getCoMailFacilitiesSlimStatus === "loading") ? <Box
          sx={{
            // border: "1px solid red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          <CustomLoader/>
          {/*{row.getVisibleCells().length}*/}
        </Box> : <></>}

        {getCoMailFacilitiesSlimStatus === "fetched" && (
          <>
            {data.map((version: any) => (
              <Box sx={{overflow: "auto", pb: 4}}>
                <CustomSummaryTable
                  testId={"summary-list"}
                  data={version.versions}
                  columnConfig={columns}
                  queryParams={pageQueryParams}
                  handleRowClick={handleRowClick}
                  updateParams={() => {
                  }}
                  hidePagination={true}
                  totalItems={data.length}
                ></CustomSummaryTable>
              </Box>
            ))}

            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              <Button data-testid={"summary_cancel_button"} onClick={onCancel}>
                Cancel
              </Button>
              <Button data-testid={"summary_save_button"} onClick={onSave} sx={{width: "100px"}}>
                {saveInProgress && (
                  <Box sx={{mr: 3}}>
                    <CustomLoader size={20}/>
                  </Box>
                )}
                Save
              </Button>
              {/*<Box sx={{width: "50px"}}></Box>*/}
              <PrimaryButton
                data-testid={"summary_proceed_button"}
                type={"button"}
                sx={{}}
                onClick={confirmJobs}
                disabled={
                  patchJobsStatus === "loading" || postSetAttrsStatus === "loading" || pendingPromises.current > 0
                }
              >
                {proceedInProgress && (
                  <Box sx={{mr: 3}}>
                    <CustomLoader size={20}/>
                  </Box>
                )}
                Proceed
              </PrimaryButton>
            </Box>
          </>
        )}
      </PageContainer>
    </ContentContainerInner>
  );
}

export default SummaryPage;

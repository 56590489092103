import styled from "@emotion/styled";
import {Box, Button, Typography} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {unusedAddressesColumns} from "./unusedConfig";
import {RootState, useAppDispatch} from "../../store/store";
import {PageContainer} from "../../styles/PageContainer";
import {useSelector} from "react-redux";
import {PaginatorModel} from "../../models/PaginatorModel";
import CustomUnusedAddressTable from "../../components/custom-unused-address-table/custom-unused-address-table";
import {deleteUnusedFile, getAdressesList, getUnusedAddressExport} from "../../store/addressesSlice";
import CustomLoader from "../../components/custom-loader/custom-loader";
import ConfirmationModal from "../../modals/confirmation-modal";
import {GeneralToast} from "../../utils/toasts";
import {setModalContentIsProgress} from "../../store/utilsSlice";
import {PrimaryButton} from "../../styles/PrimaryButton";

let TopBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ContentContainerInner = styled.div({
  flexGrow: 1,
  maxWidth: "1800px",
  width: "100%",
  position: "relative",
});

function UnusedPage() {
  const unusedAddresses = useSelector(
    (state: RootState) => state.address.unusedAddresses
  );
  const unusedAddressesStatus = useSelector(
    (state: RootState) => state.address.unusedAddressesStatus
  );

  const deleteUnusedFileStatus = useSelector(
    (state: RootState) => state.address.deleteUnusedFileStatus
  );
  const [data, setData] = useState<Array<any>>([]);
  const [pageQueryParams, setPageQueryParams] = useState<PaginatorModel | null>(
    null
  );
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const columns = useMemo(() => unusedAddressesColumns, []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const pendingPromises = useRef(0);

  useEffect(() => {
    if (unusedAddressesStatus === "loading") {
      if (!isLoading) {
        setIsLoading(true);
      }
    } else {
      if (isLoading) {
        setIsLoading(false);
      }
    }
  }, [unusedAddressesStatus]);

  useEffect(() => {
    setData(unusedAddresses);
  }, [unusedAddresses]);

  useEffect(() => {
    dispatch(getAdressesList({}));
    return () => {
    };
  }, []);

  const onHandleExport = () => {
    if (isExporting) return;
    setIsExporting(true);
    dispatch(
      getUnusedAddressExport({
        queryParams: {...pageQueryParams, output_format: "xlsx"},
      }),
    ).then((result: any) => {
      setIsExporting(false);
      if (getUnusedAddressExport.fulfilled.match(result)) {
        let payload = result.payload.data;
        const url = window.URL.createObjectURL(new Blob([payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          result.payload.filename.replace(/^_+|_+$/g, ""),
        );
        document.body.appendChild(link);
        link.click();
      } else {
        GeneralToast.error(
          result?.payload?.data?.message ??
          "Problem during exporting unused file list",
        );
      }
    });
  };


  const handleRowClick = (e: any, cell: any) => {
    e.preventDefault();
    e.stopPropagation();
    const customAttribute: string | null =
      e.target.getAttribute("data-custom-action");
    console.log("customAttribute", customAttribute);
    // const readonlyAttribute: boolean | null =
    //   e.target.getAttribute("data-readonly") === "true";
    if (!customAttribute) return;
    if (customAttribute === "remove") {
      setShowConfirmationModal(
        <ConfirmationModal
          text={"Do you really want to remove this file?"}
          header={"Confirmation"}
          onClose={(result: any) => onDeleteFileCallback(result, cell)}
        />
      );
    }
  };

  const onDeleteFileCallback = async (data: any, cell: any) => {
    if (data.status) {
      dispatch(setModalContentIsProgress(true));
      let result: any = await dispatch(
        deleteUnusedFile({
          filePk: cell.row.original.pk,
        })
      );
      dispatch(setModalContentIsProgress(false));
      if (deleteUnusedFile.fulfilled.match(result)) {
        dispatch(getAdressesList({}));
        GeneralToast.success("Unused File has been deleted");
      } else {
        GeneralToast.error("Problem during removing unused file");
      }
    }
    setShowConfirmationModal(false);
  };

  const onCancel = () => {
    navigate("/overview");
  };

  return (
    <ContentContainerInner>
      <PageContainer sx={{my: 4, mx: 2, p: 3}}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Unused address files</Typography>
            {/*<Typography variant="subtitle2">*/}
            {/*  View all recent pools and take actions*/}
            {/*</Typography>*/}
          </div>
          <div>
            <PrimaryButton
              type={"button"}
              sx={{width:"100px"}}
              onClick={onHandleExport}
              data-testid={"unused-files_export_button"}
            >
              {isExporting && (
                <Box sx={{mr: 1}}>
                  <CustomLoader size={20}/>
                </Box>
              )}
              Export
            </PrimaryButton>
          </div>
        </TopBox>

        {unusedAddressesStatus === "loading" ? (
          <Box
            sx={{
              // border: "1px solid red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <CustomLoader/>
            {/*{row.getVisibleCells().length}*/}
          </Box>
        ) : (
          <React.Fragment>
            {data?.length ? (
              <React.Fragment>
                {data.map((version: any) => (
                  <Box sx={{overflow: "auto", padding: "5px 5px 10px 5px"}}>
                    <>
                      <CustomUnusedAddressTable
                        isLoading={isLoading}
                        testId={"unusedAddresses-list"}
                        data={[version]}
                        columnConfig={columns}
                        queryParams={pageQueryParams}
                        handleRowClick={handleRowClick}
                        updateParams={() => {
                        }}
                        hidePagination={true}
                        totalItems={data.length}
                      ></CustomUnusedAddressTable>
                    </>
                  </Box>
                ))}
              </React.Fragment>
            ) : (
              <Box textAlign={"center"} sx={{my: 3}}>
                No unused files
              </Box>
            )}
          </React.Fragment>
        )}
        <Box sx={{display: "flex", justifyContent: "flex-end"}}>
          <Button data-testid={"summary_cancel_button"} onClick={onCancel}>
            Go back
          </Button>
          <Box sx={{width: "50px"}}></Box>
        </Box>
        {showConfirmationModal}
      </PageContainer>
    </ContentContainerInner>
  );
}

export default UnusedPage;

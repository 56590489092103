import {
  Box,
  Tooltip,
  Typography,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { useAppDispatch } from "../../store/store";
import CustomLoader from "../custom-loader/custom-loader";
import { CustomAMarkup } from "../../styles/CustomAMarkup";
import { setModalContent } from "../../store/utilsSlice";
import RestartIcon from "../../assets/images/restart-icon.svg";
import { restartJob } from "../../store/jobSlice";
import { GeneralToast } from "../../utils/toasts";
import {useNavigate} from "react-router-dom";
import RestartJobModal from "../../modals/restart-job-modal";

let ButtonContainer = styled(Box)<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20px;
  height: 20px;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#566C68",
    color: "#F4FAF9",
    maxWidth: 220,
    borderRadius: 2,
    fontSize: 12,
    borderWidth: 1,
    borderColor: "red",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#566C68",
  },
}));

interface Props {
  job: any;
}

function RestartJob(props: Props) {
  const navigate = useNavigate();
  const [isProgress, setIsProgress] = useState(false);
  const dispatch = useAppDispatch();

  const onConfirmationHandler = async (payload: any) => {
    if (payload?.success) {
      setIsProgress(true);
      if (!props.job || !props.job.pk) return;
      dispatch(setModalContent(null));

      let result: any = await dispatch(restartJob({ jobPk: props.job.pk }));
      setIsProgress(false);
      if (restartJob.fulfilled.match(result)) {
        GeneralToast.success("Draft Job has been created");
        navigate(`/summary/${result?.payload?.job_pk}`);
      } else {
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
            "Problem during restarting job. Please, try again later"
        );
      }
      setIsProgress(false);
    } else {
      dispatch(setModalContent(null));
      setIsProgress(false);
    }
  };

  const restartJobHandler = async () => {
    if (isProgress) return;
    dispatch(
      setModalContent(
        <RestartJobModal onClose={(result: any) => onConfirmationHandler(result)}/>
      )
    );
  };

  return (
    <>
      <CustomAMarkup>
        <ButtonContainer onClick={restartJobHandler}>
          <HtmlTooltip
            arrow
            title={
              <React.Fragment>
                <Typography color="inherit">Restart Job</Typography>
              </React.Fragment>
            }
            placement={"top"}
          >
            {isProgress ? (
              <Box sx={{ width: 20 }}>
                <CustomLoader size={15} />
              </Box>
            ) : (
              <img width={20} height={20} src={RestartIcon} />
            )}
          </HtmlTooltip>
        </ButtonContainer>
      </CustomAMarkup>
    </>
  );
}

export default RestartJob;

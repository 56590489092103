import { createColumnHelper } from "@tanstack/react-table";
import { Box } from "@mui/material";
import CustomCheckbox from "../../components/custom-checkbox/custom-checkbox";
import { NumericFormat } from "react-number-format";
import { store } from "../../store/store";
import CustomMultiSelect from "../../components/custom-multi-select/custom-multi-select";
import {
  updateSummaryPerAddressFilename,
  updateSummaryPerVersionId,
} from "../../store/summarySlice";
import CustomNumberInput from "../../components/custom-number-input/custom-number-input";

type Record = {
  version_id: string;
  bct_publisher: string;
  bct_title: string;
  address_count: string;
  file_name: string;
  scheduled: number;
  is_hd_only: boolean;
  solvent_ink: boolean;
  cr_bundle_protection_enabled: number;
  scf_pallet_protection_enabled: number;
  adc_ndc_pallet_protection_enabled: number;
  co_mail_facilities: any;
  add_a_name_amount: number;
};

let handleOnChangePerVersionId = (
  versionId: string,
  field: string,
  value: any
) => {
  store.dispatch(
    updateSummaryPerVersionId({
      versionId: versionId,
      field: field,
      value: value,
    })
  );
};

let handleOnChangePerAddressFilename = (
  fileName: string,
  field: string,
  value: any
) => {
  store.dispatch(
    updateSummaryPerAddressFilename({
      fileName: fileName,
      field: field,
      value: value,
    })
  );
};

const columnHelper = createColumnHelper<Record>();
export const summaryColumns = [
  columnHelper.accessor((row) => row.file_name, {
    id: "file_name",
    cell: (info) => {
      return <div style={{ wordBreak: "break-word" }}>{info.getValue()}</div>;
    },
    footer: (info) => info.column.id,
    minSize: 280,
    meta: {
      label: "File Name",
      enableRowSpan: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor("version_id", {
    cell: (info) => <Box textAlign={"center"}>{info.getValue()}</Box>,
    footer: (info) => info.column.id,
    minSize: 100,
    meta: {
      label: "VER ID",
      headerLabelAlignment: "center",
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.bct_publisher, {
    id: "bct_publisher",
    cell: (info) => {
      return <>{info.getValue()}</>;
    },
    meta: {
      label: "Customer",
    },
    footer: (info) => info.column.id,
    minSize: 220,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.bct_title, {
    id: "bct_title",
    cell: (info) => {
      return <>{info.getValue()}</>;
    },
    footer: (info) => info.column.id,
    minSize: 240,
    meta: {
      label: "Title",
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.address_count, {
    id: "address_count",
    cell: (info) => {
      return (
        <Box style={{ textAlign: "center" }}>
          <NumericFormat
            value={info.getValue()}
            thousandSeparator={","}
            displayType={"text"}
          />
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 80,
    meta: {
      label: "Quantity",
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.co_mail_facilities, {
    id: "co_mail_facilities",
    cell: (info) => {
      let options = store.getState().coMailFacility.coMailFacilitiesSlim;
      let validatedStatus = store.getState().summary.summaryValidatedStatus;
      let hasValue = !!info?.row?.original?.co_mail_facilities?.length;
      return (
        <Box display={"flex"} justifyContent={"center"}>
          <CustomMultiSelect
            value={info?.row?.original?.co_mail_facilities}
            selectAllOptionAvailable={true}
            options={options}
            hasError={validatedStatus === true && !hasValue}
            handleOnChange={(data: any) => {
              handleOnChangePerVersionId(
                info.row.original.version_id,
                info.column.id,
                data
              );
            }}
          />
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 110,
    meta: {
      label: "Facility",
      headerLabelAlignment: "center",
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.add_a_name_amount, {
    id: "add_a_name_amount",
    cell: (info) => {
      return (
        <Box display={"flex"} justifyContent={"center"} sx={{ px: 2 }}>
          <CustomNumberInput
            disabled={!info.row.original.scheduled}
            initialValue={0}
            value={info.getValue()}
            step={1}
            min={0}
            handleOnChange={(data: any) => {
              handleOnChangePerVersionId(
                info.row.original.version_id,
                info.column.id,
                data
              );
            }}
          ></CustomNumberInput>
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 220,
    meta: {
      label: "Count Add A Name",
      headerLabelAlignment: "center",
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.scheduled, {
    id: "scheduled",
    cell: (info) => {
      return (
        <Box display={"flex"} justifyContent={"center"}>
          <CustomCheckbox
            value={!!info.getValue()}
            dataCustomAction={"scheduled-status"}
            disabled={
              store.getState().job.changeUpdateVersionsStatus === "loading"
            }
            handleOnChange={(data: any) => {
              handleOnChangePerAddressFilename(
                info.row.original.file_name,
                info.column.id,
                data
              );
              // handleOnChange("lala", info.column.id, data);
              if (data) {
                let fieldsArray = [
                  "is_hd_only",
                  "cr_bundle_protection_enabled",
                  "scf_pallet_protection_enabled",
                  "adc_ndc_pallet_protection_enabled",
                ];
                fieldsArray.forEach((key: string) => {
                  //@ts-ignore
                  if (info.row.original[key] === true) {
                    handleOnChangePerAddressFilename(
                      info.row.original.file_name,
                      key,
                      false
                    );
                  }
                });
              } else {
                info.table.options.data.forEach((row) => {
                  handleOnChangePerVersionId(
                    row.version_id,
                    "add_a_name_amount",
                    0
                  );
                });
              }
            }}
          />
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 80,
    meta: {
      label: "Scheduled",
      headerLabelAlignment: "center",
      enableRowSpan: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.is_hd_only, {
    id: "is_hd_only",
    cell: (info) => {
      return (
        <Box display={"flex"} justifyContent={"center"}>
          <CustomCheckbox
            value={!!info.getValue()}
            dataCustomAction={"is_hd_only"}
            readonly={!!info.row.original.scheduled || !!info.row.original.solvent_ink}
            disabled={!!info.row.original.scheduled || !!info.row.original.solvent_ink}
            handleOnChange={(data: any) => {
              handleOnChangePerAddressFilename(
                info.row.original.file_name,
                info.column.id,
                data
              );
            }}
          />
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 70,
    meta: {
      label: "HD Only",
      headerLabelAlignment: "center",
      enableRowSpan: true,
    },
    enableSorting: false,
  }),
    columnHelper.accessor((row) => row.solvent_ink, {
    id: "solvent_ink",
    cell: (info) => {
      return (
        <Box display={"flex"} justifyContent={"center"}>
          <CustomCheckbox
            value={!!info.getValue()}
            dataCustomAction={"solvent_ink"}
            readonly={!!info.row.original.is_hd_only}
            disabled={!!info.row.original.is_hd_only}
            handleOnChange={(data: any) => {
              handleOnChangePerAddressFilename(
                info.row.original.file_name,
                info.column.id,
                data
              );
            }}
          />
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 70,
    meta: {
      label: "Ink Jet",
      headerLabelAlignment: "center",
      enableRowSpan: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.cr_bundle_protection_enabled, {
    id: "cr_bundle_protection_enabled",
    cell: (info) => {
      return (
        <Box display={"flex"} justifyContent={"center"}>
          <CustomCheckbox
            value={!!info.getValue()}
            dataCustomAction={"cr_bundle_protection_enabled"}
            readonly={!!info.row.original.scheduled}
            disabled={!!info.row.original.scheduled}
            handleOnChange={(data: any) => {
              handleOnChangePerAddressFilename(
                info.row.original.file_name,
                info.column.id,
                data
              );
            }}
          />
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 90,
    meta: {
      label: "CR Bundle Protection",
      headerLabelAlignment: "center",
      enableRowSpan: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.scf_pallet_protection_enabled, {
    id: "scf_pallet_protection_enabled",
    cell: (info) => {
      return (
        <Box display={"flex"} justifyContent={"center"}>
          <CustomCheckbox
            value={!!info.getValue()}
            dataCustomAction={"scf_pallet_protection_enabled"}
            readonly={!!info.row.original.scheduled}
            disabled={!!info.row.original.scheduled}
            handleOnChange={(data: any) => {
              handleOnChangePerAddressFilename(
                info.row.original.file_name,
                info.column.id,
                data
              );
            }}
          />
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 90,
    meta: {
      label: "SCF Pallet Protection",
      headerLabelAlignment: "center",
      enableRowSpan: true,
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.adc_ndc_pallet_protection_enabled, {
    id: "adc_ndc_pallet_protection_enabled",
    cell: (info) => {
      return (
        <Box display={"flex"} justifyContent={"center"}>
          <CustomCheckbox
            value={!!info.getValue()}
            dataCustomAction={"adc_ndc_pallet_protection_enabled"}
            readonly={!!info.row.original.scheduled}
            disabled={!!info.row.original.scheduled}
            handleOnChange={(data: any) => {
              handleOnChangePerAddressFilename(
                info.row.original.file_name,
                info.column.id,
                data
              );
            }}
          />
        </Box>
      );
    },
    footer: (info) => info.column.id,
    minSize: 120,
    meta: {
      label: "ADC/NDC Pallet Protection",
      headerLabelAlignment: "center",
      enableRowSpan: true,
    },
    enableSorting: false,
  }),
];

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiOptimization } from "../utils/requests";

export interface CoreState {
  postageEffectiveDates: any;
  tenantDetails: any;
  postageEffectiveDatesStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  tenantDetailsStatus: "idle" | "loading" | "failed" | "fetched" | "submitted";
  enumsStatus: "idle" | "loading" | "failed" | "fetched" | "submitted";
  enumObject: any;
  enumArray: Array<any>;
}

const initialState: CoreState = {
  postageEffectiveDates: [],
  tenantDetails: null,
  postageEffectiveDatesStatus: "idle",
  tenantDetailsStatus: "idle",
  enumsStatus: "idle",
  enumObject: {},
  enumArray: [],
};

export const getEnums = createAsyncThunk("app/getEnums", async (thunkAPI) => {
  const response = await apiOptimization.get(`v2/client/common/enums`);
  return response.data;
});

export const getPostageEffectiveDates = createAsyncThunk(
  "core/getPostageEffectiveDates",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.get(
      `v2/client/postage/postage-effective-dates`,
      {
        params: data.queryParams,
      }
    );
    return response.data;
  }
);

export const patchPostageEffectiveDates = createAsyncThunk(
  "core/patchPostageEffectiveDates",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.patch(
      `v2/client/postage/postage-effective-dates`,
      {},
      { params: data.queryParams }
    );
    return response.data;
  }
);

export const getTenantDetails = createAsyncThunk(
  "core/getTenantDetails",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.get(`v2/client/common/tenant-details`, {
      params: data.queryParams,
    });
    return response.data;
  }
);

export const coreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPostageEffectiveDates.pending, (state) => {
      state.postageEffectiveDates = [];
      state.postageEffectiveDatesStatus = "loading";
    });
    builder.addCase(getPostageEffectiveDates.fulfilled, (state, action) => {
      state.postageEffectiveDates = [...action.payload];
      state.postageEffectiveDatesStatus = "fetched";
    });
    builder.addCase(getPostageEffectiveDates.rejected, (state) => {
      state.postageEffectiveDates = [];
      state.postageEffectiveDatesStatus = "failed";
    });
    builder.addCase(getTenantDetails.pending, (state) => {
      state.tenantDetailsStatus = "loading";
    });
    builder.addCase(getTenantDetails.fulfilled, (state, action) => {
      state.tenantDetails = action.payload;
      state.tenantDetailsStatus = "fetched";
    });
    builder.addCase(getTenantDetails.rejected, (state) => {
      state.tenantDetailsStatus = "failed";
    });
    builder.addCase(getEnums.pending, (state) => {
      state.enumsStatus = "loading";
    });
    builder.addCase(getEnums.fulfilled, (state, action) => {
      state.enumObject = action.payload;
      state.enumsStatus = "fetched";
    });
    builder.addCase(getEnums.rejected, (state, action) => {
      state.enumObject = null;
      state.enumsStatus = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = coreSlice.actions;

export default coreSlice.reducer;

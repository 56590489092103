import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import * as yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton } from "../styles/PrimaryButton";
import { NumericFormat } from "react-number-format";
import { useAppDispatch } from "../store/store";
import { GeneralToast } from "../utils/toasts";
import CustomLoader from "../components/custom-loader/custom-loader";
import {
  getCoMailFacilityLineConfig,
  patchCoMailFacilityLineConfig,
  postCoMailFacilityLineConfig,
} from "../store/coMailFacilitySlice";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ModalContainer = styled(Box)({
  backgroundColor: "white",
});

let FormContainer = styled.form({
  height: "100%",
  width: "100%",
});

interface Props {
  onClose: any;
  line?: any;
  facilityPk: number;
}

const addLineConfigurationValidationSchema = yup.object({
  grouped_lines: yup
    .number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1)
    .required("Required")
    .label("Grouped Lines"),
  pockets: yup
    .number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1)
    .required("Required")
    .label("Pockets"),
  min_quantity_per_line: yup
    .number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1)
    .required("Required")
    .label("Min Quantity Per Line")
    .test(
      "min",
      "${path} must be less or equal Max Quantity All Lines",
      (value, context) => {
        return value <= context.parent.max_quantity_all_lines;
      }
    ),
  max_quantity_all_lines: yup
    .number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1)
    .required("Required")
    .label("Max Quantity All Lines")
    .test(
      "max",
      "${path} must be greater or equal Min Quantity Per Line",
      (value, context) => {
        return value >= context.parent.min_quantity_per_line;
      }
    ),
  is_hd_only: yup.boolean().label("HD Only"),
  solvent_ink: yup.boolean().label("Ink Jet"),
});
export const AddLineConfigurationModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState<any>({
    pockets: "",
    grouped_lines: "",
    max_quantity_all_lines: "",
    min_quantity_per_line: "",
    is_hd_only: false,
    solvent_ink: false,
  });
  const {
    getValues,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
    resolver: yupResolver(addLineConfigurationValidationSchema),
  });

  useEffect(() => {
    if (!defaultValue) return;
    reset(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (props.line) {
      updateForm();
    }
  }, [props.line]);

  const updateForm = async () => {
    setIsLoading(true);
    let result: any = await dispatch(
      getCoMailFacilityLineConfig({
        facilityPk: props.facilityPk,
        lineConfigPk: props.line.pk,
      })
    );
    if (getCoMailFacilityLineConfig.fulfilled.match(result)) {
      reset({
        pockets: result.payload.pockets,
        grouped_lines: result.payload.grouped_lines,
        max_quantity_all_lines: result.payload.max_quantity_all_lines,
        min_quantity_per_line: result.payload.min_quantity_per_line,
        is_hd_only: result.payload.is_hd_only,
        solvent_ink: result.payload.solvent_ink,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    if (props.line?.pk) {
      setIsLoading(true);
      let result: any = await dispatch(
        patchCoMailFacilityLineConfig({
          facilityPk: props.facilityPk,
          lineConfigPk: props.line.pk,
          data: data,
        })
      );
      if (patchCoMailFacilityLineConfig.fulfilled.match(result)) {
        setIsLoading(false);
        onClose({ status: 1 });
        GeneralToast.success("Line Config has been edited");
      } else {
        setIsLoading(false);
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
            "Problem during editing Line Config"
        );
      }
    } else {
      setIsLoading(true);
      let result: any = await dispatch(
        postCoMailFacilityLineConfig({
          coMailFacilityId: props.facilityPk,
          data: data,
        })
      );
      if (postCoMailFacilityLineConfig.fulfilled.match(result)) {
        setIsLoading(false);
        onClose({ status: 1 });
        GeneralToast.success("Line Config has been added");
      } else {
        setIsLoading(false);
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
            "Problem during adding Line Config"
        );
      }
    }
  };

  const onClose = (payload: any) => {
    props.onClose(payload);
  };
  return (
    <Dialog open={true} onClose={() => {}}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer sx={{ p: 3 }}>
          <TopBox
            sx={{
              pb: 2,
            }}
          >
            <div>
              <Typography variant="h5">Add Line Configuration</Typography>
              <Typography variant="subtitle2">Setup new line</Typography>
            </div>
            <div></div>
          </TopBox>
          <DialogContent sx={{ position: "relative" }}>
            <Box>
              {isLoading && (
                <Grid
                  container
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.5)",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    zIndex: 10,
                  }}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <CustomLoader />
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="grouped_lines"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        data-testid={
                          "add-line-configuration-modal_grouped-lines_input"
                        }
                        label={"Grouped Lines"}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={value || undefined}
                        name={name}
                        customInput={TextField}
                        thousandSeparator={","}
                        onValueChange={(values) => {
                          onChange(
                            // @ts-ignore
                            isNaN(values.floatValue)
                              ? undefined
                              : values.floatValue
                          );
                        }}
                        error={!!errors.hasOwnProperty("grouped_lines")}
                        helperText={
                          errors?.grouped_lines?.message
                            ? `${errors?.grouped_lines?.message}`
                            : null
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="pockets"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        data-testid={
                          "add-line-configuration-modal_pockets_input"
                        }
                        label={"Pockets"}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={value || undefined}
                        name={name}
                        customInput={TextField}
                        thousandSeparator={","}
                        onValueChange={(values) => {
                          onChange(
                            // @ts-ignore
                            isNaN(values.floatValue)
                              ? undefined
                              : values.floatValue
                          );
                        }}
                        error={!!errors.hasOwnProperty("pockets")}
                        helperText={
                          errors?.pockets?.message
                            ? `${errors?.pockets?.message}`
                            : null
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="min_quantity_per_line"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        data-testid={
                          "add-line-configuration-modal_min-quantity-per-line_input"
                        }
                        label={"Min Quantity Per Line"}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={value || undefined}
                        name={name}
                        customInput={TextField}
                        thousandSeparator={","}
                        onValueChange={(values) => {
                          onChange(
                            // @ts-ignore
                            isNaN(values.floatValue)
                              ? undefined
                              : values.floatValue
                          );
                        }}
                        error={!!errors.hasOwnProperty("min_quantity_per_line")}
                        helperText={
                          errors?.min_quantity_per_line?.message
                            ? `${errors?.min_quantity_per_line?.message}`
                            : null
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="max_quantity_all_lines"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        data-testid={
                          "add-line-configuration-modal_max-quantity-all-lines_input"
                        }
                        label={"Max Quantity All Lines"}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={value || undefined}
                        name={name}
                        customInput={TextField}
                        thousandSeparator={","}
                        onValueChange={(values) => {
                          onChange(
                            // @ts-ignore
                            isNaN(values.floatValue)
                              ? undefined
                              : values.floatValue
                          );
                        }}
                        error={
                          !!errors.hasOwnProperty("max_quantity_all_lines")
                        }
                        helperText={
                          errors?.max_quantity_all_lines?.message
                            ? `${errors?.max_quantity_all_lines?.message}`
                            : null
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="is_hd_only"
                    render={({ field: { onChange, name, value } }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="HD Only"
                        ></FormControlLabel>
                      </FormGroup>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="solvent_ink"
                    render={({ field: { onChange, name, value } }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Ink Jet"
                        ></FormControlLabel>
                      </FormGroup>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          {/*<DialogActions>*/}
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 4 }}>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"flex-start"}
            >
              <Button
                onClick={onClose}
                data-testid={"add-line-configuration-modal_go-back_button"}
              >
                Go Back
              </Button>
            </Box>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"center"}
            >
              <PrimaryButton
                type="submit"
                data-testid={"add-line-configuration-modal_continue_button"}
              >
                Continue
              </PrimaryButton>
            </Box>
            <Box
              sx={{ width: "33%" }}
              display={"flex"}
              justifyContent={"flex-end"}
            ></Box>
          </Box>
        </ModalContainer>
      </FormContainer>
    </Dialog>
  );
};
export default AddLineConfigurationModal;

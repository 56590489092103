import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {ThemeProvider as ThemeProviderEmotion} from "@emotion/react";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {MsalProvider} from "@azure/msal-react";
import {loginRequest, msalInstance} from "./authConfig";
import {EventType, RedirectRequest} from "@azure/msal-browser";
import {setInitialized, setUserProfile} from "./store/appSlice";
import {setLoggedUserData} from "./store/userSlice";
import {apiUserManager} from "./utils/requests";

const themeEmotion = {
  colors: {
    primary: "#AEAD28",
    secondary: "#003F34",
    link: "#67827D",
    lightGray: "#F1F1F1",
    errorBackground: "#FFE9E9",
    error: "#FF2E2E",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#AEAD28",
      light: "#AEAD28",
      dark: "#AEAD28",
      contrastText: "white",
    },
    secondary: {
      main: "#003F34",
      light: "#003F34",
      dark: "#003F34",
      contrastText: "white",
    },
    error: {
      main: "#FF2E2E",
      light: "#FFE9E9",
      dark: "#FF2E2E",
      contrastText: "white",
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    subtitle2: {
      color: "#A0A0A0",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#6E6972",
          "&.Mui-focused": {
            color: "#6E6972",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          borderRadius: "2px",
          // "&.Mui-focused": {
          //   backgroundColor: "transparent",
          // },
          // "& fieldset": {
          //   borderColor: "#6E6972",
          // },
          // "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "inherit",
          // },
          // "&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "inherit",
          // },
          // ":hover .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "#6E6972",
          // },
          // "&.Mui-focused fieldset": {
          //   borderWidth: "1px",
          // },
        },
      },
    },
  },
});

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
  apiUserManager.post(`v1/server/tokens/`).then((result) => {
    store.dispatch(setUserProfile(accounts[0]));
  });
  // localStorage.setItem("user", JSON.stringify(accounts[0]));
}
msalInstance.addEventCallback(
  (event) => {
    if (event.eventType === EventType.INITIALIZE_END) {
      // store.dispatch(setInitialized(true));
    }
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      //@ts-ignore
      event!.payload!.account
    ) {
      //@ts-ignore
      const account = event!.payload!.account;
      msalInstance.setActiveAccount(account);
      apiUserManager.post(`v1/server/tokens/`).then((result) => {
        store.dispatch(setUserProfile(account));
      });
      // @ts-ignore
      if (event.payload && event.payload.state) {
      }
    }
  },
  //@ts-ignore
  (error) => {
  }
);

msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    if (tokenResponse) {
      localStorage.setItem("accessToken", tokenResponse?.accessToken || "");
    }
    const account = msalInstance.getActiveAccount();
    if (account) {
      store.dispatch(
        setLoggedUserData({
          name: account.name,
          role: account?.idTokenClaims?.role,
        })
      );
    }
    if (!account) {
      let params = {};
      params = {
        scopes: loginRequest.scopes,
      };
      if (window.location.origin + "/" === window.location.href) {
        msalInstance.loginRedirect(params as RedirectRequest);
      } else {
        window.location.href = "/";
      }
      // }
    } else {
      store.dispatch(setInitialized(true));
      // Do something with the tokenResponse
    }
  })
  .catch((err) => {
    // Handle error
  });

// msalInstance.enableAccountStorageEvents();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProviderEmotion theme={themeEmotion}>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <App/>
        </MsalProvider>
      </ThemeProvider>
    </ThemeProviderEmotion>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { apiOptimization } from "../utils/requests";

export interface AdressesState {
  unusedAddresses: any;
  unusedAddressesStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  deleteUnusedFileStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
}

const initialState: AdressesState = {
  unusedAddresses: [],
  unusedAddressesStatus: "idle",
  deleteUnusedFileStatus: "idle",
};

export const getAdressesList = createAsyncThunk(
  "addresses/getList",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.get(`v2/client/addresses/files/unused`, {
      params: data.queryParams,
    });
    return response.data;
  }
);

export const deleteUnusedFile = createAsyncThunk(
  "addresses/unusedFiles/delete",
  async (payload: any, thunkAPI) => {
    try {
      const response = await apiOptimization.delete(
        `v2/client/addresses/files/unused/${payload.filePk}`,
        payload.data
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUnusedAddressExport = createAsyncThunk(
  "addresses/getUnusedAddressExport",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.get(`v2/client/addresses/files/unused/export`, {
      params: data.queryParams,
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer",
    });
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition
      .split(";")[1]
      .trim()
      .split("=")[1]
      .replaceAll('"', "");
    return {
      data: response.data,
      filename: filename,
    };
  }
);

export const configurationSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdressesList.pending, (state) => {
      state.unusedAddressesStatus = "loading";
    });
    builder.addCase(getAdressesList.fulfilled, (state, action) => {
      state.unusedAddresses = [...action.payload];
      state.unusedAddressesStatus = "fetched";
    });
    builder.addCase(getAdressesList.rejected, (state, action) => {
      state.unusedAddresses = [];
      state.unusedAddressesStatus = "failed";
    });
    builder.addCase(deleteUnusedFile.pending, (state) => {
      state.deleteUnusedFileStatus = "loading";
    });
    builder.addCase(deleteUnusedFile.fulfilled, (state, action) => {
      state.deleteUnusedFileStatus = "fetched";
    });
    builder.addCase(deleteUnusedFile.rejected, (state, action) => {
      state.deleteUnusedFileStatus = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = configurationSlice.actions;

export default configurationSlice.reducer;

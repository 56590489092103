import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../styles/PrimaryButton";
import { RootState, useAppDispatch } from "../store/store";

import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import ActiveTick from "../components/active-tick/active-tick";
import { patchCoMailFacilityProcessingSequence } from "../store/coMailFacilitySlice";
import { GeneralToast } from "../utils/toasts";
import CustomLoader from "../components/custom-loader/custom-loader";

let TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let TableTh = styled("th")`
  text-align: left;
  font-size: 12px;
  font-weight: medium;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  //fontfamily: "Roboto,sans-serif";
`;

let TableTr = styled("tr")`
  background-color: white;

  td {
    border: 1px solid #e2e2e2;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 2px;
    margin-bottom: 2px;

    :not(:first-child):not(:last-child) {
      border-left: none;
      border-right: none;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: none;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: none;
    }
  }
`;

let ModalContainer = styled(Box)({
  backgroundColor: "white",
});

let FormContainer = styled.form({
  height: "100%",
  width: "100%",
});

interface Props {
  onClose: any;
  // line?: any;
  // facilityPk: number;
}

export const SortLines = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const modalContent = useSelector(
    (state: RootState) => state.utils.modalContent
  );
  const coMailFacilities = useSelector(
    (state: RootState) => state.coMailFacility.coMailFacilities
  );
  const patchCoMailFacilityProcessingSequenceStatus = useSelector(
    (state: RootState) =>
      state.coMailFacility.patchCoMailFacilityProcessingSequenceStatus
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    let fff = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener("dragover", fff);

    return () => {
      document.removeEventListener("dragover", fff);
    };
  }, []);

  useEffect(() => {
    setData(coMailFacilities);
  }, [coMailFacilities]);

  const onSubmit = async (x: any) => {
    let payload = [
      ...data.map((x: any, i: number) => {
        return {
          pk: x.pk,
          processing_sequence: i + 1,
          line_configs: x.line_configs.map((y: any, j: number) => {
            return { pk: y.pk, processing_sequence: j + 1 };
          }),
        };
      }),
    ];
    let result: any = await dispatch(
      patchCoMailFacilityProcessingSequence({ payload: payload })
    );
    if (patchCoMailFacilityProcessingSequence.fulfilled.match(result)) {
      GeneralToast.success("Order has been saved");
      onClose({ status: 1 });
    } else {
      GeneralToast.error(
        result?.payload?.response?.data?.message ??
          "Problem during saving order"
      );
    }
  };

  const onClose = (payload: any) => {
    props.onClose(payload);
  };

  const sortInternal = (payload: any, index: number) => {
    let arr: any = JSON.parse(JSON.stringify(data));
    arr[index]["line_configs"] = [...payload];
    setData(arr);
  };

  return (
    <Dialog open={true} onClose={() => {}} fullWidth={true}>
      <ModalContainer sx={{ p: 3 }}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Order Configuration</Typography>
            <Typography variant="subtitle2">
              Drag and Drop to reorder
            </Typography>
          </div>
          <div></div>
        </TopBox>
        {data?.length && (
          <DialogContent sx={{ position: "relative", p: 1 }}>
            <ReactSortable
              disabled={false}
              animation={400}
              handle={".sort-handle"}
              list={JSON.parse(JSON.stringify(data))}
              setList={setData}
              // dragClass={"sortable-class"}
            >
              {data.map((facility: any, index) => (
                <Box
                  key={facility.pk}
                  className={"sort-handle"}
                  sx={{
                    background: "white",
                    // padding: "10px",
                    boxShadow: "0px 0px 3px 0px #d7d7d7",
                    // border: "1px solid red",
                    padding: "20px 20px 30px 20px",
                    // marginBottom: 30,
                    // fontWeight: "bold",
                    marginBottom: "40px",
                    borderRadius: "10px",
                  }}
                >
                  <Box sx={{ pb: 2, fontWeight: "bold" }}>{facility.name}</Box>
                  <Box>
                    {/*<table style={{ borderCollapse: "collapse" }}>*/}
                    {/*<table>*/}
                    <table
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <TableTh>Grouped Lines</TableTh>
                          <TableTh>Pockets</TableTh>
                          <TableTh>Min Quantity Per Line</TableTh>
                          <TableTh>Max Quantity</TableTh>
                          <TableTh>HD Only</TableTh>
                          <TableTh>Ink Jet</TableTh>
                        </tr>
                      </thead>
                      {/*<tbody>*/}
                      <ReactSortable
                        disabled={false}
                        handle={".sort-handle-internal"}
                        list={JSON.parse(JSON.stringify(facility.line_configs))}
                        setList={(data) => sortInternal(data, index)}
                        animation={400}
                        tag={"tbody"}
                        // dragClass={"sortable-class"}
                      >
                        {facility.line_configs.map((lineConfig: any) => (
                          <TableTr
                            className={"sort-handle-internal"}
                            key={lineConfig.pk}
                          >
                            <td>{lineConfig.grouped_lines}</td>
                            <td>{lineConfig.pockets}</td>
                            <td>{lineConfig.min_quantity_per_line}</td>
                            <td>{lineConfig.max_quantity_all_lines}</td>
                            <td>
                              <ActiveTick value={lineConfig.is_hd_only}/>
                            </td>
                            <td>
                              <ActiveTick value={lineConfig.solvent_ink}/>
                            </td>
                          </TableTr>
                        ))}
                      </ReactSortable>
                      {/*</tbody>*/}
                    </table>
                    {/*// <Box >{y.pk}</Box>*/}
                  </Box>
                </Box>
              ))}
            </ReactSortable>
          </DialogContent>
        )}
        {/*    /!*<DialogActions>*!/*/}
        <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 4 }}>
          <Box
            sx={{ width: "33%" }}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Button
              onClick={onClose}
              data-testid={"sort-facilities-modal_go-back_button"}
            >
              Go Back
            </Button>
          </Box>
          <Box sx={{ width: "33%" }} display={"flex"} justifyContent={"center"}>
            <PrimaryButton
              type="button"
              onClick={onSubmit}
              data-testid={"sort-facilities-modal_save_button"}
            >
              {patchCoMailFacilityProcessingSequenceStatus === "loading" && (
                <Box sx={{ mr: 3 }}>
                  <CustomLoader size={20} />
                </Box>
              )}
              Save
            </PrimaryButton>
          </Box>
          <Box
            sx={{ width: "33%" }}
            display={"flex"}
            justifyContent={"flex-end"}
          ></Box>
        </Box>
      </ModalContainer>
    </Dialog>
  );
};
export default SortLines;

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import * as yup from "yup";
import React, {useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {PrimaryButton} from "../styles/PrimaryButton";
import {RootState, useAppDispatch} from "../store/store";
import CustomLoader from "../components/custom-loader/custom-loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  patchCoMailFacility,
  postCoMailFacility,
} from "../store/coMailFacilitySlice";
import {GeneralToast} from "../utils/toasts";
import {useSelector} from "react-redux";
import FacilityEntryFileDropzone from "../components/facility-entry-file-dropzone/facility-entry-file-dropzone";
import {getBase64} from "../utils/getBase64";
import FacilityScfRunFileDropzone from "../components/facility-scf-run-file-dropzone/facility-scf-run-file-dropzone";

let TopBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ModalContainer = styled(Box)({
  backgroundColor: "white",
});

let FormContainer = styled.form({
  height: "100%",
  width: "100%",
});

interface Props {
  onClose: any;
  facility: any;
}

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#566C68",
    color: "#F4FAF9",
    maxWidth: 350,
    borderRadius: 2,
    fontSize: 12,
    borderWidth: 1,
    borderColor: "red",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#566C68",
  },
}));

const addFacilityValidationSchema = yup.object({
  name: yup.string().required("Required").label("Name"),
  zip_ranges: yup.string().when({
    is: (exists: string) => !!exists,
    then: (rule) =>
      rule.matches(
        /^(\d{1,5}-\d{1,5}|\d{1,5})(,\s?(\d{1,5}-\d{1,5}|\d{1,5}))*$/,
        "Wrong Zip Code Range format"
      ),
  }),
});

export const AddFacilityModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const [uploadFileError, setUploadFileError] = useState<any>(null);
  const [entryListFile, setEntryListFile] = useState<any>(null);
  const [scfRunFile, setScfRunFile] = useState<any>(null);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const tenantConfiguration = useSelector(
    (state: RootState) => state.core.tenantDetails?.configuration
  );
  const [defaultValue, setDefaultValue] = useState<any>({
    name: "",
    zip_ranges: "",
    entry_list_file: null,
    scf_run_file: null,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
    resolver: yupResolver(addFacilityValidationSchema),
  });

  useEffect(() => {
    if (!defaultValue) return;
    reset(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (props.facility) {
      updateForm();
    }
  }, [props.facility]);

  const updateForm = async () => {
    setIsLoading(true);
    let zipRanges: string = "";

    if (props.facility.zip_ranges?.length) {
      props.facility.zip_ranges.forEach((zip: any, index: number) => {
        zipRanges += `${zip.start}-${zip.end}${
          index < props.facility.zip_ranges.length - 1 ? ", " : ""
        }`;
      });
    }
    reset({
      name: props.facility.name,
      zip_ranges: zipRanges || "",
    });
    setIsLoading(false);
  };

  const onSubmit = async (data: any) => {
    if (inProgress) return;
    setInProgress(true);

    let entryFileBase64 = null;
    if (entryListFile) {
      entryFileBase64 = await getBase64(entryListFile, () => {
      });
    }

    let scfRunBase64 = null;
    if (scfRunFile) {
      scfRunBase64 = await getBase64(scfRunFile, () => {
      });
    }

    let zipRanges: any = [];
    if (data.zip_ranges && data.zip_ranges.length) {
      let zipRange = data.zip_ranges.replaceAll(" ", "").split(",");
      zipRange.forEach((x: any) => {
        let splitted = x.split("-");
        if (splitted.length == 1) {
          zipRanges.push({start: splitted[0], end: null});
        } else {
          zipRanges.push({start: splitted[0], end: splitted[1]});
        }
      });
    }
    console.log("file===========", entryListFile);

    let payload = {
      name: data.name,
      zip_ranges: zipRanges,
      entry_list_file: entryFileBase64
        ? {
          // @ts-ignore
          content: entryFileBase64.replaceAll("data:text/csv;base64,", ""),
          name: entryListFile.name,
        }
        : null,
      scf_run_file: scfRunBase64
        ? {
          // @ts-ignore
          content: scfRunBase64.replaceAll("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,", ""),
          name: scfRunFile.name,
        }
        : null,
    };

    if (props?.facility?.pk) {
      let result: any = await dispatch(
        patchCoMailFacility({
          pk: props.facility.pk,
          data: payload,
        })
      );
      if (patchCoMailFacility.fulfilled.match(result)) {
        setInProgress(false);
        props.onClose({status: 1});
        GeneralToast.success("Co-mail Facility has been updated");
        onClose({success: true});
      } else {
        setInProgress(false);
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
          "Problem during updating Co-mail Facility"
        );
      }
    } else {
      let result: any = await dispatch(
        postCoMailFacility({
          payload: payload,
        })
      );
      if (postCoMailFacility.fulfilled.match(result)) {
        setInProgress(false);
        props.onClose({status: 1});
        GeneralToast.success("Co-mail Facility has been added");
        onClose({success: true});
      } else {
        setInProgress(false);
        GeneralToast.error(
          result?.payload?.response?.data?.message ??
          "Problem during creating Co-mail Facility"
        );
      }
    }
  };

  const handleOnDrop = (data: any, field: string) => {
    if (field === 'entry_list') {
      setEntryListFile(data);
    }
    if (field === 'scf_run_file') {
      setScfRunFile(data);
    }
  };

  const handleOnClear = (data: any, field: string) => {
    if (field === 'entry_list') {
      setEntryListFile(null);
    }
    if (field === 'scf_run_file') {
      setScfRunFile(null);
    }

  };

  const onClose = (payload: any) => {
    props.onClose(payload);
  };
  return (
    <Dialog open={true} onClose={() => {
    }}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer sx={{p: 3}}>
          <TopBox
            sx={{
              pb: 2,
            }}
          >
            <div>
              <Typography variant="h5">
                {props.facility ? "Edit" : "Add"} Facility
              </Typography>
              <Typography variant="subtitle2">
                {props.facility ? "Edit" : "Add"} details of the facility
              </Typography>
            </div>
            <div></div>
          </TopBox>
          <DialogContent sx={{position: "relative"}}>
            <Box>
              {isLoading && (
                <Grid
                  container
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.5)",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    zIndex: 10,
                  }}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <CustomLoader/>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="name"
                    render={({field}) => (
                      <TextField
                        sx={{width: "100%"}}
                        {...field}
                        inputProps={{
                          ["data-testid"]: "add-facility-modal_name_input",
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        error={!!errors.hasOwnProperty("name")}
                        helperText={
                          errors?.name?.message
                            ? `${errors?.name?.message}`
                            : null
                        }
                        label="Name"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sx={{pt: "5px !important"}}>
                  <Box display={"flex"} alignItems={"center"}>
                    <InfoOutlinedIcon color={"primary"}/>{" "}
                    <Box sx={{px: 1}}>
                      <Typography variant={"subtitle2"} sx={{lineHeight: 1}}>
                        Maximum 140 characters
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/*{tenantConfiguration?.can_set_zip_ranges && (*/}
                <Grid item xs={12}>
                  <FacilityEntryFileDropzone
                    testId={"add-facility-modal"}
                    onDrop={(e: any) => handleOnDrop(e, 'entry_list')}
                    onClear={(e: any) => handleOnClear(e, 'entry_list')}
                    label={"Load Tariff Files"}
                    currentFile={props?.facility?.entry_list}
                  ></FacilityEntryFileDropzone>
                </Grid>
                {/*)}*/}

                {/*{tenantConfiguration?.can_set_zip_ranges && (*/}
                <Grid item xs={12} sx={{pt: "5px !important"}}>
                  <Box display={"flex"} alignItems={"center"}>
                    <InfoOutlinedIcon color={"primary"}/>{" "}
                    <Box sx={{px: 1}}>
                      <Typography variant={"subtitle2"} sx={{lineHeight: 1}}>
                        File format: CSV
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {/*)}*/}

                {tenantConfiguration.enable_pocket_sharing &&
                    <>
                        <Grid item xs={12}>
                            <FacilityScfRunFileDropzone
                                testId={"add-facility-modal"}
                                onDrop={(e: any) => handleOnDrop(e, 'scf_run_file')}
                                onClear={(e: any) => handleOnClear(e, 'scf_run_file')}
                                label={"SCF Run File"}
                                currentFile={props?.facility?.scf_run_file}
                            ></FacilityScfRunFileDropzone>
                        </Grid>

                        <Grid item xs={12} sx={{pt: "5px !important"}}>
                            <Box display={"flex"} alignItems={"center"}>
                                <InfoOutlinedIcon color={"primary"}/>{" "}
                                <Box sx={{px: 1}}>
                                    <Typography variant={"subtitle2"} sx={{lineHeight: 1}}>
                                        File format: XLSX
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </>
                }

                {!!tenantConfiguration?.can_set_zip_ranges && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="zip_ranges"
                      render={({field}) => (
                        <TextField
                          sx={{width: "100%"}}
                          {...field}
                          inputProps={{
                            ["data-testid"]:
                              "add-facility-modal_zip-code-range_input",
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          error={!!errors.hasOwnProperty("zip_ranges")}
                          helperText={
                            errors?.zip_ranges?.message
                              ? `${errors?.zip_ranges?.message}`
                              : null
                          }
                          label="ZIP Code Range"
                        />
                      )}
                    />
                  </Grid>
                )}
                {!!tenantConfiguration?.can_set_zip_ranges && (
                  <Grid item xs={12} sx={{pt: "5px !important"}}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      // flexDirection={"row"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <InfoOutlinedIcon color={"primary"}/>{" "}
                        <Box sx={{px: 1}}>
                          <Typography
                            variant={"subtitle2"}
                            sx={{lineHeight: 1}}
                          >
                            For example: 1-3, 5, 725
                          </Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <HtmlTooltip
                          arrow
                          title={
                            <Box sx={{p: 3}}>
                              <Typography color="inherit">
                                Specifing a ZIP range for a given facility will
                                result in filtering out pieces falling outside
                                of that range.
                              </Typography>
                              <Box sx={{pt: 2}}>
                                <Typography color="inherit">
                                  No ZIP range provided will be treated as a
                                  full range, meaning: 0-9.
                                </Typography>
                              </Box>
                              <Box sx={{pt: 2}}>
                                <Typography color="inherit">
                                  Input 100-200, 3, 4000, 51281 will be treated
                                  as a following sum of ranges: 10000-20099,
                                  30000-39999, 40000-40009, 51281.
                                </Typography>
                              </Box>
                            </Box>
                          }
                          placement={"top"}
                        >
                          <Typography
                            variant={"subtitle2"}
                            sx={{lineHeight: 1, textDecoration: "underline"}}
                          >
                            More Info
                          </Typography>
                        </HtmlTooltip>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </DialogContent>
          {/*<DialogActions>*/}
          <Box display={"flex"} justifyContent={"space-between"} sx={{mt: 4}}>
            <Box
              sx={{width: "33%"}}
              display={"flex"}
              justifyContent={"flex-start"}
            >
              <Button
                onClick={onClose}
                data-testid={"add-facility-modal_go-back_button"}
              >
                Go Back
              </Button>
            </Box>
            <Box
              sx={{width: "33%"}}
              display={"flex"}
              justifyContent={"center"}
            >
              <PrimaryButton
                type="submit"
                data-testid={"add-facility-modal_save_button"}
              >
                {!!inProgress && (
                  <Box sx={{mr: 3}}>
                    <CustomLoader size={20}/>
                  </Box>
                )}
                Save
              </PrimaryButton>
            </Box>
            <Box
              sx={{width: "33%"}}
              display={"flex"}
              justifyContent={"flex-end"}
            ></Box>
          </Box>
        </ModalContainer>
      </FormContainer>
    </Dialog>
  );
};
export default AddFacilityModal;

import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { apiOptimization } from "../utils/requests";
import { JobSummaryModel } from "../models/JobSummaryModel";

export interface SummaryState {
  summary: Array<{
    address_filename: string;
    versions: Array<JobSummaryModel>;
  }>;
  loadingSummaryListStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  summaryValidatedStatus: boolean | null;
}

const initialState: SummaryState = {
  summary: [],
  summaryValidatedStatus: null,
  loadingSummaryListStatus: "idle",
};

export const getSummaryList = createAsyncThunk(
  "summary/getList",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.get(
      `v2/client/jobs/${data.payload.pk}/address-files`
    );
    return response.data;
  }
);

export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    clearSummaryData: (state) => {
      state.summary = [];
    },
    updateSummaryPerVersionId: (state, action) => {
      const currentState: any = current(state);
      let preparedData = JSON.parse(JSON.stringify([...currentState.summary]));
      preparedData.forEach((addressFilename: any) => {
        addressFilename.versions.forEach((version: any) => {
          if (version.version_id === action.payload.versionId) {
            version[action.payload.field] = action.payload.value;
          }
        });
      });

      state.summary = preparedData;
    },
    updateSummaryPerAddressFilename: (state, action) => {
      const currentState: any = current(state);
      let preparedData = JSON.parse(JSON.stringify([...currentState.summary]));
      let foundIndex = preparedData.findIndex(
        (x: any) => x.file_name === action.payload.fileName
      );

      preparedData[foundIndex].versions.forEach((x: any) => {
        x[action.payload.field] = action.payload.value;
      });

      state.summary = preparedData;
    },
    updateSummaryValidationStatus: (state, action) => {
      state.summaryValidatedStatus = action.payload;
      state.summary = [...state.summary];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSummaryList.pending, (state) => {
      state.summary = [];
      state.loadingSummaryListStatus = "loading";
    });
    builder.addCase(getSummaryList.fulfilled, (state, action) => {
      action.payload.forEach((x: any) => {
        x.versions.forEach((y: any) => {
          y.file_name = x.file_name;
        });
      });
      state.summary = [...action.payload];
      state.loadingSummaryListStatus = "fetched";
    });
    builder.addCase(getSummaryList.rejected, (state, action) => {
      state.loadingSummaryListStatus = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateSummaryPerVersionId,
  updateSummaryPerAddressFilename,
  updateSummaryValidationStatus,
  clearSummaryData
} = summarySlice.actions;

export default summarySlice.reducer;

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {PrimaryButton} from "../styles/PrimaryButton";

let TopBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props: any) => `1px solid ${props.theme.colors.lightGray}`};
`;

let ModalContainer = styled(Box)({
  backgroundColor: "white",
});

interface Props {
  onClose: any;
}

const MAIN_TEST_ID = "job-creation-warning";

export const RestartJobModal = (props: Props) => {
  const onClose = (data: any) => {
    props.onClose(data);
  };

  return (
    <Dialog open={true} maxWidth={'xs'} fullWidth onClose={() => {
    }}>
      <ModalContainer sx={{p: 3}}>
        <TopBox
          sx={{
            pb: 2,
          }}
        >
          <div>
            <Typography variant="h5">Warning!</Typography>
          </div>
        </TopBox>
        <DialogContent sx={{px:0}}>
          <Box>

            <Box sx={{my:2}}>Would you like to restart?</Box>

          </Box>
        </DialogContent>
        <Box display={"flex"} justifyContent={"space-around"} >
          <Box
            sx={{width: "33%"}}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Button
              data-testid={`${MAIN_TEST_ID}_go-back_button`}
              onClick={onClose}
              variant={"text"}
              color={'secondary'}
            >
              Cancel
            </Button>
          </Box>
          <Box
            sx={{width: "33%"}}
            display={"flex"}
            justifyContent={"center"}
          >
            <PrimaryButton
              type="button"
              onClick={() => onClose({success: 1})}
              data-testid={`${MAIN_TEST_ID}_continue_button`}
              variant={"contained"}
              color={"primary"}
              sx={{px:5, textTransform:"initial"}}
            >
              Restart
            </PrimaryButton>
          </Box>
        </Box>
      </ModalContainer>
    </Dialog>
  );
};
export default RestartJobModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiOptimization } from "../utils/requests";

export interface LineState {
  lines: any;
  loadingLineListStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  loadingPostLineListStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  loadingPutLineListStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  loadingDeleteLineListStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  // modalContent: any;
}

const initialState: LineState = {
  lines: [],
  loadingLineListStatus: "idle",
  loadingPostLineListStatus: "idle",
  loadingPutLineListStatus: "idle",
  loadingDeleteLineListStatus: "idle",
};

export const getLineList = createAsyncThunk(
  "lines/getList",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.get(`v1/line_configs`, {
      params: data.queryParams,
    });
    return response.data;
  }
);

export const getLineDetails = createAsyncThunk(
  "lines/getListDetails",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.get(`v1/line_configs/${data.id}`, {
      params: data.queryParams,
    });
    return response.data;
  }
);

export const postLine = createAsyncThunk(
  "lines/postLine",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.post(`v1/line_configs`, data);
    return response.data;
  }
);

export const putLine = createAsyncThunk(
  "lines/putLine",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.put(`v1/line_configs/${data.id}`, data.payload);
    return response.data;
  }
);

export const deleteLine = createAsyncThunk(
  "lines/deleteLine",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.delete(`v1/line_configs/${data.id}`, data);
    return response.data;
  }
);

export const lineSlice = createSlice({
  name: "line",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLineList.pending, (state) => {
      state.loadingLineListStatus = "loading";
    });
    builder.addCase(getLineList.fulfilled, (state, action) => {
      state.lines = [...action.payload];
      state.loadingLineListStatus = "fetched";
    });
    builder.addCase(getLineList.rejected, (state, action) => {
      state.loadingLineListStatus = "failed";
    });
    builder.addCase(postLine.pending, (state) => {
      state.loadingPostLineListStatus = "loading";
    });
    builder.addCase(postLine.fulfilled, (state, action) => {
      state.loadingPostLineListStatus = "fetched";
    });
    builder.addCase(postLine.rejected, (state) => {
      state.loadingPostLineListStatus = "failed";
    });
    builder.addCase(putLine.pending, (state) => {
      state.loadingPutLineListStatus = "loading";
    });
    builder.addCase(putLine.fulfilled, (state, action) => {
      state.loadingPutLineListStatus = "fetched";
    });
    builder.addCase(putLine.rejected, (state) => {
      state.loadingPutLineListStatus = "failed";
    });
    builder.addCase(deleteLine.pending, (state) => {
      state.loadingDeleteLineListStatus = "loading";
    });
    builder.addCase(deleteLine.fulfilled, (state, action) => {
      state.loadingDeleteLineListStatus = "fetched";
    });
    builder.addCase(deleteLine.rejected, (state) => {
      state.loadingDeleteLineListStatus = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = lineSlice.actions;

export default lineSlice.reducer;

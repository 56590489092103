import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { format } from "date-fns";

let FieldLabel = styled(Box)`
  background-color: white;
  padding: 2px 5px;
  position: absolute;
  top: -9px;
  left: 10px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #6e6972;
  pointer-events: none;
`;

let UploadBox = styled(Box)((props: any) => ({
  backgroundColor: "#f2f9f8",
  borderRadius: "2px",
  // border: `1px solid ${alpha(props.theme.palette.text.primary, 0.2)}`,
  border: "1px solid #C4C4C4",
}));

interface Props {
  onDrop: any;
  onClear: any;
  testId?: string;
  currentFile?: any;
  label?: any;
  // status: string | undefined;
}

function FacilityEntryFileDropzone(props: Props) {
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [initializedFileField, setInitializedFileField] = useState(false);

  useEffect(() => {
    if (props.currentFile) {
      let tmpFile = new File([], props.currentFile.file_name, {
        lastModified: parseInt(
          format(new Date(props.currentFile.created_date_time), "T")
        ),
      });

      setUploadedFile(tmpFile);
      setInitializedFileField(true);
    }
  }, [props.currentFile]);

  const onDrop = useCallback((acceptedFiles: any) => {
    setInitializedFileField(false);
    // Do something with the files
    setUploadedFile(acceptedFiles[0]);
    props.onDrop(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeFile = (e: any) => {
    e.stopPropagation();
    setUploadedFile(null);
    props.onClear();
  };

  return (
    <>
      <UploadBox
        sx={{
          opacity: isDragActive ? 0.7 : 1,
          position: "relative",
          cursor: !uploadedFile ? "pointer" : "cursor",
        }}
        justifyContent={"center"}
        data-testid={props.testId ? `${props.testId}_dropzone_area` : ""}
      >
        {props.label && <FieldLabel>{props.label}</FieldLabel>}
        <div {...getRootProps()}>
          <input
            multiple={false}
            {...getInputProps()}
            data-testid={
              props.testId ? `${props.testId}_dropzone_area_input` : ""
            }
          />
          {isDragActive ? (
            <Box
              sx={{ p: 3 }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FileUploadOutlinedIcon style={{ color: "#5A7772" }} />
              <Typography variant={"subtitle2"} sx={{ color: "#5A7772" }}>
                Drop the file here...
              </Typography>
            </Box>
          ) : (
            <Box sx={{ p: 3 }}>
              {!uploadedFile && (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  // justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FileUploadOutlinedIcon
                    style={{ color: "#5A7772", marginRight: 10 }}
                  />
                  <Box>
                    <Typography variant={"subtitle2"} sx={{ color: "#67827D" }}>
                      Drag file or <b>browse</b> for a file to upload
                    </Typography>
                    {initializedFileField && (
                      <Typography
                        variant={"subtitle2"}
                        sx={{ color: "#868686", fontStyle: "italic" }}
                      >
                        (Last entry uploaded:{" "}
                        {props.currentFile
                          ? format(
                              new Date(props.currentFile.created_date_time),
                              "LLL dd, yyyy hh:mm a"
                            )
                          : "no file provided"}
                        )
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
              {!!uploadedFile && (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  // justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FileUploadOutlinedIcon
                    style={{ color: "#5A7772", marginRight: 10 }}
                  />
                  <Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant={"subtitle2"}
                        sx={{ color: "#5A7772" }}
                      >
                        Uploaded <b>{uploadedFile && uploadedFile["name"]}</b>
                      </Typography>
                      <HighlightOffIcon
                        onClick={removeFile}
                        sx={{
                          color: "#5A7772",
                          fontSize: 15,
                          p: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                    {initializedFileField && (
                      <Typography
                        variant={"subtitle2"}
                        sx={{ color: "#868686", fontStyle: "italic" }}
                      >
                        (Last entry uploaded:{" "}
                        {format(
                          new Date(uploadedFile["lastModified"]),
                          "LLL dd, yyyy hh:mm a"
                        )}
                        )
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </div>
      </UploadBox>
    </>
  );
}

export default FacilityEntryFileDropzone;

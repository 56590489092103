import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiOptimization } from "../utils/requests";

export interface ConfigurationState {
  configurations: any;
}

const initialState: ConfigurationState = {
  configurations: [],
};

export const getConfigurationList = createAsyncThunk(
  "configurations/getList",
  async (data: any, thunkAPI) => {
    const response = await apiOptimization.get(`v1/configurations`, {
      params: data.queryParams,
    });
    return response.data;
  }
);

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const {} = configurationSlice.actions;

export default configurationSlice.reducer;
